.list-container {
    margin: 30px;
    color:black 
}

.head-1 {
    color:aliceblue;
}
.list-1 {
   font-size: 1.4vh;
   color:#0f0e0f;
}
.emails-container {
  margin:15px; 
  padding:10px; 
}
.email-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
