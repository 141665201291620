
.submit-btn{
    display:flex;
    justify-content: center;
    
}
.h4{
    color:rgb(13, 13, 14);
    font-weight: 700;

}
.input-text {
    height:4vh;
    padding:5px;
    margin: 5px;
    border-radius:5px ;
    border-width: 1px;
    font-size: 1.4vh;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) 

}
.input-1 {
    border-radius: 5px;
    padding:5px;
    border-width: 1px;
    font-size: 1.4vh;
    width:3vw;
    height:4vh;
    margin: 5px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) 

}
.submit-button {
    background-color: rgb(20, 114, 43);
    font-size: 1vh;
    color:white;
    border-radius: 5px;
    border-width: 0px;
    min-width:4vw;
    height:4vh;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) 

}
.btn {
    height:4vh;
    font-size:1vh;
    text-align: center;
    border-radius: 15px;
    box-shadow: 2px 2px 2px 0 rgba(19, 18, 18, 0.2), 0 4px 10px 0 rgba(17, 2, 2, 0.19) 

}
.btn-danger {
    background-color: red;
    min-width:2vw;
    border-radius: 5px;
    font-size: 1.5vh;
    text-align: center;
}
.btn-danger-1 {
    background-color: red;
    min-width:4vw;
    font-size: 1vh;
    color:white;
    text-align: center;
}
.inputfiled-container {
    display: flex;
    flex-wrap: wrap;
}
::placeholder {
    font-size: 1.1vh;
}



