.body {
    margin: 0px;
    padding: 0px;
    font-size: 0.8vh;
}
.bgconatainer {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #05a0fa 0%, #c1f5ef 100%);
    width:100vw;
    min-height:100vh;
    background-size: cover;
}
.header-1 {
    background-color: white;
    color:rgb(6, 9, 177);
    min-height:3vh;
    width:100vw;
    box-shadow: 20px 15px 15px 0 rgba(13, 13, 14, 0.2), 0 10px 20px 0 rgba(15, 15, 15, 0.19);
    display:flex; 
    justify-content: space-between;
}
.rhym-logo {
    min-width:13vw;
    height:5vh;
    margin-left: 10px;
    padding:2px;
}
.control-btn {
    margin-top: 20px;
    text-align: center;
    align-items: center;
    min-width:7vw;
}
.button-container {
    display: flex;
    margin-right: 10px;

}
.link {
    color: rgb(16, 43, 221);
    font-size: 1.2vh;
    font-weight:500;
    text-decoration: none;
}
.btn-cnt-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;
}
.carousel-item {
    margin:20px;

}

.list-button {
    display: flex;
    justify-content:space-between;
    text-align: center;
    align-items: center;
    min-width: 5vw;
    min-height: 4vh;
    font-size: 1.5vh;
    border-width: 0px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0 rgba(13, 13, 14, 0.2), 0 10px 20px 0 rgba(15, 15, 15, 0.19);

} 
.vr {
    color:black;
    height:5vh;
    margin: 5px;
    width:2px;
}
.avatar {
    height: 3vh;
    width:auto;
    margin-top: 4.5px;
    
}
.avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

}
.p {
    font-size: 1.1vh;
    font-weight:500;
    color: rgb(16, 43, 221);  
}
.slider {
    max-width:40vw;
    box-shadow: rgba(46, 45, 45, 0.2) 0px 7px 29px 0px;
    background: linear-gradient(112.9deg, rgb(198, 237, 253) 6.2%, rgb(20, 157, 221) 99.7%);
    border-radius: 15px;
}
.slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.event-note {
    color:#0052e9;


}