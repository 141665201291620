.view-container {
    padding: 10px;
    font-size: 0.7vh;
   
}

.table-head
{
    max-width: 100px;
    word-wrap: break-word;
     text-overflow: ellipsis;
     
}
.table-data
{
    max-width: 100px;
    word-wrap: break-word;
     overflow: hidden;
     text-overflow: ellipsis;
     border: 1px solid #ddd
   
}
.edit-Button {
    background-color:rgb(40, 86, 212);
    height:2.5vh;
    color:white;
    border-width: 0px;
    border-radius: 5px;
    font-size: 1vh;
}
.delete-button {
    background-color: red;
    height:2.3vh;
    color:white;
    border-width: 0px;
    border-radius: 5px;
    font-size: 0.4vh;

}
/* .snackbar-container {
    position: fixed;
    top: 16px; 
    right: 16px; 
    color:blue
  } */
  .nav-btn
  {
   
        height:4vh;
        font-size:1.5vh;
        text-align: center;
        background-color:rgb(40, 86, 212);
        color:white;
        border-radius:50px;

    
  }
  .flex-container{
    display: flex;
    justify-content: space-between;
  }
  .standard-card {
    width:90vw;
    margin-bottom: 15px;
    color:white;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 5px;
    background-image: linear-gradient(135deg, #274cf0 0%, #7f0df0 100%);
   
    border-color: linear-gradient(112.9deg, rgb(150, 152, 240) 6.2%, rgb(11, 163, 233) 99.7%);

  }
.table {
    width:100%;
    font-size: 1.3vh;
    
}
.Accordian {
    width:90vw;
    margin-bottom: 10px;
    background-image: linear-gradient(135deg, rgb(102, 126, 233) 0%rgb(97, 45, 150)a2 100%);
    font-family:Arial, Helvetica, sans-serif;



}
.table-refno{
    width:4vw;
}
.table-subcontrol {
    width:50vw;
}
.options {
    width:90vw;
    margin-bottom: 20px;
    font-size: 1.7vh;
    padding:10px;
    color:#7f0df0;
}
 